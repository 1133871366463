.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(12px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.App-table {
    border-collapse: collapse;
    margin: auto;
    padding: 40px;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 85%;
    height: 85%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    position: relative;
}

.App-table thead tr {
    background-color: #D12030;
    color: #ffffff;
    text-align: left;
}

.App-table th,
.App-table td {
    padding: 12px 15px;
    text-align: left;
}

.App-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.App-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.App-table tbody tr:last-of-type {
    border-bottom: 2px solid #ff4d4d;
}

.App-table tbody tr:hover {
    color: #D12030;
}

.App-box {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 20px 0 50px 0;
    /* background-color: #fff7f7; */
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
    /* border-radius: 5px; */
}

.App-talk {
    width: 98%;
    height: 100%;
    margin: 10px;
    text-align: left;
    padding: 20px 0 20px 20px;
    background-color: #efefff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    min-height: 220px;
}

.speaker {
    font-size: 1.3em;
    display: inline;
    padding-left: 10px;
}

.talktitle {
    font-size: 1.4em;
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight:bold;
}

.abstract {
    font-size: 0.8em;
    margin-top: 5px;
}

.speakerbio {
    font-size: 0.8em;
    margin-top: 5px;
}

.zoomlink {

}


.host {

}

.room {

}

.photo {
    display: inline;
    position: relative;
    bottom: 0px;
    margin-bottom: 5px;
    left: 10px;
    float: right;
    width: 200px;
    height: 200px;
    margin: 0px;
    margin-right: 30px;
    border: 5px solid black;
}

.photo img {
    object-fit: cover;
    width: 200px;
    height: 200px;
}

.btn {
    padding: 3px;
    margin: 3px;
    font-size: 1.3em;
}

.overview {
    width: 75%;
    margin: auto;
    padding: 20px 0 50px 20px;
    text-align: left;
    background-color: #fff7f7;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
